import React, { FC } from 'react'
import Link from 'next/link'

interface LinkBtnProps {
  text: string
  href: string
  onClick?: () => void
}

const LinkBtn: FC<LinkBtnProps> = ({
  text,
  href,
  onClick
}): JSX.Element =>
  <Link href={href}>
    <a
      className="block w-full py-3 px-7 text-center bg-mf-dark-blue mt-0 hover:-mt-px hover:mb-px shadow-md hover:shadow-lg transition duration-150 ease-in-out rounded-md text-md font-medium text-white hover:opacity-95 sm:inline-block sm:w-auto"
      onClick={onClick}
    >
      {text}
    </a>
  </Link>

export default LinkBtn
